import { ApolloProvider } from "@apollo/client";
import { useApollo } from "@shared/lib/Apollo/apolloClient";
import { AppProps } from "next/app";
import React from "react";
import "../src/styles/global.css";
import { extend, locale } from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import "dayjs/locale/cs";
import "./obchodni-podminky.css";
import { ICONS_VERSION } from "@generated/icons";
import { ICONS } from "@generated/icons";
import "./styles.css";
import "leaflet/dist/leaflet.css";
import { UiContextProvider, UiContextType } from "@uxf/ui/context";
import { COLOR_SCHEME_DEFAULTS } from "@uxf/ui/color-scheme";
import { config } from "@config/config";
import { twScreens } from "@config/tw-tokens/tw-screens";
import { LocalizeProvider } from "../src/localize";
import { FlashMessages, getFlashMessagesRef } from "@uxf/ui/flash-messages";
import { getModalRef, Modal } from "@uxf/ui/modal";

const UI_PROVIDER_CONFIG: UiContextType = {
    colorScheme: {
        defaultColorScheme: "light",
        getUserSettings: COLOR_SCHEME_DEFAULTS.getUserSettings,
        respectSystemSetting: false,
        respectUserSettings: false,
        setUserSettings: COLOR_SCHEME_DEFAULTS.setUserSettings,
    },
    domain: config.FRONTEND_URL ?? "",
    icon: { spriteFilePath: `/icons-generated/_icon-sprite.svg?v=${ICONS_VERSION}`, iconsConfig: ICONS },
    rasterImage: { breakpoints: twScreens },
};

function UxfApp(props: AppProps) {
    const { Component, pageProps } = props;
    const apollo = useApollo(pageProps);
    extend(LocalizedFormat);
    locale("cs");

    return (
        <UiContextProvider value={UI_PROVIDER_CONFIG}>
            <ApolloProvider client={apollo}>
                <LocalizeProvider value="cs">
                    <Component {...pageProps} />
                    <FlashMessages ref={getFlashMessagesRef()} />
                    <Modal ref={getModalRef()} />
                </LocalizeProvider>
            </ApolloProvider>
        </UiContextProvider>
    );
}

export default UxfApp;
