// this file is generated automatically, do not change anything manually in the contents of this file

export const ICONS_VERSION = "b0a01608ba70b26858ee9ec0c886808d";

export const ICONS = {
    "arrow-left": { w: 448, h: 512 },
    "arrow-right": { w: 448, h: 512 },
    "calendar": { w: 448, h: 512 },
    "camera": { w: 512, h: 512 },
    "caretDown": { w: 320, h: 512 },
    "clock": { w: 512, h: 512 },
    "bars": { w: 448, h: 512 },
    "check": { w: 512, h: 512 },
    "chevronDown": { w: 512, h: 512 },
    "chevronLeft": { w: 24, h: 24 },
    "chevronsLeft": { w: 512, h: 512 },
    "chevronRight": { w: 24, h: 24 },
    "chevronsRight": { w: 512, h: 512 },
    "chevronUp": { w: 512, h: 512 },
    "cloud": { w: 640, h: 512 },
    "copy": { w: 512, h: 512 },
    "ellipsis-vertical": { w: 128, h: 512 },
    "eye": { w: 576, h: 512 },
    "eye-slash": { w: 640, h: 512 },
    "file": { w: 384, h: 512 },
    "imageFile": { w: 384, h: 512 },
    "triangle-exclamation": { w: 512, h: 512 },
    "user": { w: 448, h: 512 },
    "videoFile": { w: 384, h: 512 },
    "xmark": { w: 384, h: 512 },
    "xmarkLarge": { w: 448, h: 512 },
    "circle-info": { w: 512, h: 512 },
    "filter": { w: 512, h: 512 },
    "pen": { w: 512, h: 512 },
    "table": { w: 512, h: 512 },
    "file-arrow-down": { w: 384, h: 512 },
    "table-columns": { w: 640, h: 512 },
    "search": { w: 512, h: 512 },
    "file-download": { w: 384, h: 512 },
    "trash": { w: 448, h: 512 },
    "sort": { w: 320, h: 512 },
    "sort-up": { w: 320, h: 512 },
    "sort-down": { w: 320, h: 512 },
    "warning": { w: 512, h: 512 },
    "google": { w: 27, h: 27 },
    "burger": { w: 24, h: 24 },
    "info": { w: 25, h: 25 },
    "babyFriendly": { w: 17, h: 17 },
    "compass": { w: 24, h: 24 },
    "climbingRestrictions": { w: 17, h: 17 },
    "documentText": { w: 24, h: 24 },
    "position": { w: 17, h: 17 },
    "type-of-protection": { w: 17, h: 17 },
    "faPro_regular.book-user": { w: 448, h: 512 },
    "faPro_regular.xmark": { w: 384, h: 512 },
    "faPro_regular.bars": { w: 448, h: 512 },
    "faPro_regular.book": { w: 448, h: 512 },
    "faPro_regular.file-lines": { w: 384, h: 512 },
    "faPro_regular.list": { w: 512, h: 512 },
    "faPro_regular.map": { w: 576, h: 512 },
    "faPro_solid.triangle-exclamation": { w: 512, h: 512 },
    "faPro_solid.star": { w: 576, h: 512 },
    "faPro_solid.ban": { w: 512, h: 512 },
    "faPro_brands.facebook": { w: 512, h: 512 },
    "faPro_brands.apple": { w: 384, h: 512 },
    "faPro_regular.square-check": { w: 448, h: 512 },
} as const;

export type IconType = keyof typeof ICONS;

declare module "@uxf/ui/icon/theme" {
    interface IconsSet {
        "arrow-left": true;
        "arrow-right": true;
        "calendar": true;
        "camera": true;
        "caretDown": true;
        "clock": true;
        "bars": true;
        "check": true;
        "chevronDown": true;
        "chevronLeft": true;
        "chevronsLeft": true;
        "chevronRight": true;
        "chevronsRight": true;
        "chevronUp": true;
        "cloud": true;
        "copy": true;
        "ellipsis-vertical": true;
        "eye": true;
        "eye-slash": true;
        "file": true;
        "imageFile": true;
        "triangle-exclamation": true;
        "user": true;
        "videoFile": true;
        "xmark": true;
        "xmarkLarge": true;
        "circle-info": true;
        "filter": true;
        "pen": true;
        "table": true;
        "file-arrow-down": true;
        "table-columns": true;
        "search": true;
        "file-download": true;
        "trash": true;
        "sort": true;
        "sort-up": true;
        "sort-down": true;
        "warning": true;
        "google": true;
        "burger": true;
        "info": true;
        "babyFriendly": true;
        "compass": true;
        "climbingRestrictions": true;
        "documentText": true;
        "position": true;
        "type-of-protection": true;
        "faPro_regular.book-user": true;
        "faPro_regular.xmark": true;
        "faPro_regular.bars": true;
        "faPro_regular.book": true;
        "faPro_regular.file-lines": true;
        "faPro_regular.list": true;
        "faPro_regular.map": true;
        "faPro_solid.triangle-exclamation": true;
        "faPro_solid.star": true;
        "faPro_solid.ban": true;
        "faPro_brands.facebook": true;
        "faPro_brands.apple": true;
        "faPro_regular.square-check": true;
    }
}
